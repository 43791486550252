<template>
  <div class="infinite-load">
    <infinite-loading @infinite="getData" :identifier="infiniteId" direction="bottom" class="infinite-text"
      ref="infinite" :force-use-infinite-wrapper="wrapperClass">
      <div slot="spinner" class="text">加载中...</div>
      <div slot="no-more" class="text">已加载全部</div>
      <div slot="no-results">
        <el-empty :description="noResText"></el-empty>
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import infiniteLoading from "vue-infinite-loading";
export default {
  components: { infiniteLoading },
  name: "loading",
  props: {
    handleInfinite: {
      type: Object,
    },
    noResText: {
      type: String,
      default: "暂无数据",
    },
    wrapperClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      infiniteId: +new Date(),
    };
  },
  methods: {
    getData($state) {  
      const infinite = this.infiniteId;
      if (infinite === this.infiniteId) {
        this.$emit("getData", $state);
      }
    },
    reSet() {
      this.infiniteId += 1;
    },
  },
};
</script>

<style scoped>
.infinite-load {
  width: 100%;
}

.infinite-text {
  color: #b3d3ff;
  padding: 10px 0;
  font-size: 16px;
}

.text {
  color: #969799;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
}
</style>