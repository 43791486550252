import $ from "jquery"
import * as regExp from "./regExp"
import {
  setItem
} from "./localStorage"
import {
  Message
} from "element-ui"
import {mapKey,mapChannal} from "../config/project";
const getMapKey = () => {
  return mapKey[Math.floor(Math.random() * mapKey.length)]
}
const getMapChannal = () => {
  return mapChannal[Math.floor(Math.random() * mapChannal.length)]
}
// 获取当前时间
const getCurrentTime = function () {
    const {
      yy,
      mm,
      dd,
      hou,
      min,
      sec,
      week } = formartDate()
  let nowDate = yy + '年' + mm + '月' + dd + '日'
  let nowTime = hou + ':' + min + ':' + sec
  let nowWeek = week

  return {
    timer: {
      nowDate,
      nowTime,
      nowWeek
    },
    timeStr: `${nowDate} ${nowTime} ${nowWeek}`
  }
}
// 基础格式化时间
const formartDate = function (val) {
  let myDate = ''
  if (val) {
    myDate = new Date(val)
  } else {
    myDate = new Date()
  }
  let wk = myDate.getDay()
  let yy = String(myDate.getFullYear())
  let m1 = myDate.getMonth() + 1
  let mm = String(m1 < 10 ? '0' + m1 : m1)
  let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
  let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
  let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
  let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
  let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  let week = weeks[wk];
  return {
    myDate,
    wk,
    yy,
    mm,
    dd,
    hou,
    min,
    sec,
    week
  }
}
const transformDate = function (val, split) {
  if (!val) {
    return;
  }
  const {
    yy,
    mm,
    dd,
    hou,
    min,
    sec,
    week } = formartDate(val);
  let nowDate = yy + (split ? split : '年') + mm + (split ? split : '月') + dd + (split ? '' : '日')
  let nowTime = hou + ':' + min + ':' + sec
  let nowWeek = week
  return {
    nowDate,
    nowTime,
    nowWeek
  }
}
// 页面大小变化
const resize = function () {
  let ratioX = $(window).width() / 1920;
  let ratioY = $(window).height() / 1080;
  setItem("ratioX", ratioX)
  setItem("ratioY", ratioY)
  $("#app").css({
    transform: "scale(" + ratioX + "," + ratioY + ")",
    //transformOrigin: "0px 0px 0px",
    transformOrigin: "left top",
    // backgroundSize: "100% 100%",
    // overflow: "hidden",
    width: "1920px",
    height: "1080px",
  });

  $("html").css({
    'overflow': 'hidden'
  })
}
// 简单的深拷贝
const clone = obj => {
  let copy
  // 处理简单数据类型、null和undefined
  if (obj == null || typeof obj !== 'object') return obj
  // 处理日期
  if (obj instanceof Date) {
    copy = new Date()
    copy.setTime(obj.getTime())
    return copy
  }
  // 处理数组
  if (obj instanceof Array) {
    copy = []
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i])
    }
    return copy
  }
  // 处理对象
  if (obj instanceof Object) {
    copy = {}
    for (const attr in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, attr)) copy[attr] = clone(obj[attr])
    }
    return copy
  }

  return false
}
// 获取传入参数的类型
const getType = (val) => {
  return Object.prototype.toString.call(val).split(' ')[1].replace(']', '')
}

// 获取图片转base64
const imageFileToBase64 = (file) => {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    let imgResult = "";
    reader.readAsDataURL(file);
    reader.onload = function () {
      imgResult = reader.result;
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.onloadend = function () {
      resolve(imgResult);
    };
  });
}

const exportEx = (urlText, name = 'excel', type = '.xlsx') => {
  return new Promise((res) => {
    const url = window.URL.createObjectURL(
      new Blob([urlText], {
        type: "application/vnd.ms-excel;charset=utf-8",
      })
    );
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    // a.setAttribute("download", `${name}.xlsx`);
    a.setAttribute("download", `${name}${type}`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    Message({
      message: "导出成功！正在下载...",
      type: "success",
      center: true,
    });
    res(true)
  })
}
const exportUrl = (urlText, name = 'excel', type = '.xlsx') => {
  return new Promise((res) => {
    const url = window.URL.createObjectURL(
      urlText
    );
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    // a.setAttribute("download", `${name}.xlsx`);
    a.setAttribute("download", `${name}${type}`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    Message({
      message: "导出成功！正在下载...",
      type: "success",
      center: true,
    });
    res(true)
  })
}
 // 导出excel
 const exportOut = (flow, name, type = "application/x-xls") => {
  let content = flow;
  let blob = new Blob([content], {
    type: type,
  });
  let fileName = name;
  if ("download" in document.createElement("a")) {
    // 非IE下载
    let link = document.createElement("a");
    link.download = fileName;
    link.style.display = "none";
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href); // 释放URL对象
    document.body.removeChild(link);
  } else {
    navigator.msSaveBlob(blob, fileName); // IE10+ 下载
  }
}

const exportImage = (urlText, name = '图标') => {
  return new Promise((res) => {
    var a = document.createElement('a')
    var event = new MouseEvent('click')
    // 自定义下载后图片的名称
    a.download = name
    a.href = urlText
    a.dispatchEvent(event)
    Message({
      message: "导出成功！正在下载...",
      type: "success",
      center: true,
    });
    res(true)
  })
}

const isUrl = (str) => {
  if (!str) {
    return false;
  }
  return /^(?:http(s)?:\/\/)?[\w.-]+(?:.[\w.-]+)+[\w\-._~:/?#[\]@!$&'*+,;=.]+$/.test(str);
}
/**
 * 
 * @param {azimuth='方位角',elevation='俯仰角',Radius='半径'} params 
 * @returns 
 */
// const getXY = (params) => {
//   const { elevation, azimuth, Radius, } = params;
//   let len = Math.cos((elevation * Math.PI) / 180) * Radius;
//   return {
//     y: Math.cos((azimuth * Math.PI) / 180) * len,
//     x: Math.sin((azimuth * Math.PI) / 180) * len
//   }
// }
const degreesToRads = deg => (deg * Math.PI) / 180.0;
const getXY = (params) => {
  const { elevation, azimuth, Radius, } = params;
  let len = Radius / 90;
  return {
    y: (Radius - len * elevation) * Math.sin(degreesToRads(azimuth + 90)) + Radius,
    x: Radius - (Radius - len * elevation) * Math.cos(degreesToRads(azimuth + 90))
  }
}
/**
 * 复制单行内容到粘贴板
 * 
 */
function copyToClip(value) {
  return navigator.clipboard.writeText(value);
}
// localStorage监听重写
function dispatchEventStroage () {
  const signSetItem = localStorage.setItem
  localStorage.setItem = function (key, val) {
      let setEvent = new Event('setItemEvent')
      setEvent.key = key
      setEvent.newValue = val
      window.dispatchEvent(setEvent)
      signSetItem.apply(this, arguments)
  }
}
function toFixedFn(num, fix = 2, round = true) {
  // fix && (fix = parseInt(fix));
  // var f = parseFloat(num);
  if (fix === 0) {
    return num.toString().split(".")[0]
  }
  if (num && isNaN(num)) {
    return num;
  }
  num = +num;
  num *= Math.pow(10, fix);
  if (round) {
    num = Math.round(num);
  }else{
    num = Math.ceil(num);
  }

  let s = num / Math.pow(10, fix);
  return s;
}
function dataURLToBlob(fileDataURL, filename) {
  let bstr = atob(fileDataURL),
    n = fileDataURL.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: 'xlsx' });
}
export default {
  transformDate,
  formartDate,
  getCurrentTime,
  resize,
  clone,
  getType,
  imageFileToBase64,
  exportEx,
  exportImage,
  isUrl,
  copyToClip,
  getXY,
  regExp,
  dispatchEventStroage,
  exportOut,
  exportUrl,
  toFixedFn,
  getMapKey,
  getMapChannal,
  dataURLToBlob
}