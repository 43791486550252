const validValueNumAndLetter = (val) => {
    return /^[A-Za-z0-9]+$/.test(val)
}
const validIp = (val) => {
    return /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/.test(val)
}
// 度 经度校验
// /receiver/editWorkModel --- lng
const validLng = (val) => {
    return /^(-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/.test(val)
}

// 度 纬度校验
// /receiver/editWorkModel --- lat
const validLat = (val) => {
    return /^(-|\+)?([0-8]?\d{1}\.\d{0,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/.test(val)
}
const validateName = (val) => {
    return new RegExp(
        "[`~!@#$--+^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]"
    ).test(val)
}
export {
    validValueNumAndLetter,
    validIp,
    validLng,
    validLat,
    validateName
}