import request from "../utils/http"
// import axios from "./httpp"
import tool from "@/utils/tool"

// 登录
export function login(param) {
  return request({
    url: "/v1/gnss/login",
    method: "post",
    data: param,
  })
}
// 退出
export function loginOut(param) {
  return request({
    url: "/v1/gnss/logout",
    method: "get",
    params: param,
  })
}
// 用户列表
export function userList(param) {
  return request({
    url: "/v1/gnss/user/list",
    method: "get",
    params: param,
  })
}
// 用户管理-经销商列表
export function agentList(param) {
  return request({
    url: "/v1/gnss/agent/list",
    method: "get",
    params: param,
  })
}
// 用户管理-删除用户
export function delUser(param) {
  return request({
    url: "/v1/gnss/user/delete",
    method: "post",
    data: param,
  })
}
// 用户管理-重置密码
export function resetPwd(param) {
  return request({
    url: "/v1/gnss/user/reset",
    method: "post",
    data: param,
  })
}
// 用户管理-编辑用户
export function updUser(param) {
  return request({
    url: "/v1/gnss/user/update",
    method: "post",
    data: param,
  })
}
// 用户管理-添加用户
export function addUser(param) {
  return request({
    url: "/v1/gnss/user/add",
    method: "post",
    data: param,
  })
}
// 用户管理-新增-校验账号是否存在
export function checkAccount(param) {
  return request({
    url: "/v1/gnss/user/account",
    method: "get",
    params: param,
  })
}
// 用户管理-获取地区列表
export function regionList(param) {
  return request({
    url: "/v1/gnss/region/get",
    method: "get",
    params: param,
  })
}
// 设备管理-添加设备-校验SN是否存在
export function checkSN(param) {
  return request({
    url: "/v1/gnss/device/sn",
    method: "get",
    params: param,
  })
}
// 登录-验证码登录
export function getCaptcha(param) {
  return request({
    url: "/v1/gnss/captcha",
    method: "get",
    params: param,
  })
}
// 设备管理-设备列表
export function deviceList(param) {
  return request({
    url: "/v1/gnss/device/list",
    method: "get",
    params: param,
  })
}
// 设备管理-设备删除
export function delList(param) {
  return request({
    url: "/v1/gnss/device/device",
    method: "post",
    data: param,
  })
}
// 设备管理-用户下拉列表
export function userAll(param) {
  return request({
    url: "/v1/gnss/user/all",
    method: "get",
    params: param,
  })
}
// 数据管理-数据列表
export function dataList(param) {
  return request({
    url: "/v1/gnss/track/list",
    method: "post",
    data: param,
  })
}
// 分配设备
export function receiverAssign(param) {
  return request({
    url: "/v1/gnss/device/assign",
    method: "post",
    data: param,
  })
}

// 设备管理-批量添加设备
export function batchAdd(param) {
  return request({
    url: "/v1/gnss/device/batch",
    method: "post",
    data: param,
  })
}
// 接收机管理-接收机状态 / 接收机状态-位置信息
export function deviceDetail(param) {
  return request({
    url: "/v1/gnss/device/detail",
    method: "get",
    params: param,
  })
}
// 升级文件上传
export function upgradeFileUpload(param) {
  return request({
    url: "/v1/gnss/device/upload",
    method: "post",
    data: param,
  })
}
// 固件升级
export function deviceUpgrade(param) {
  return request({
    url: "/v1/gnss/device/upgrade",
    method: "post",
    data: param,
  })
}
// 接收机管理-接收机信息-重启接收机和定位模块
export function deviceReset(param) {
  return request({
    url: "/v1/gnss/device/diagnosis",
    method: "post",
    data: param,
  })
}
// 数据管理-导出数据
export function exportData(param) {
  return request({
    url: "/v1/gnss/track/export",
    method: "post",
    data: param,
    responseType: 'blob' // 类型
  })
}
// 查询该账号下所有sn
export function getSN(param) {
  return request({
    url: "/v1/gnss/device/sn",
    method: "post",
    data: param,
  })
}
// 位置地图设备列表
// export function positionList(param) {
//   return request({
//     url: "/v1/r12/device/all",
//     method: "post",
//     data: param,
//   })
// }
// 设备管理-添加设备
export function addDevice(param) {
  return request({
    url: "/v1/gnss/device/add",
    method: "post",
    data: param,
  })
}
// 位置地图-截面点对列表
export function sectionList(data) {
  return request({
    url: "/v1/gnss/line/list",
    method: "get",
    params:data
  })
}
// 新增截面点对
export function addSection(data) {
  return request({
    url: "/v1/gnss/line/add",
    method: "post",
    data
  })
}
// 删除截面点对
export function delSection(data) {
  return request({
    url: "/v1/gnss/line/delete",
    method: "post",
    data
  })
}
// 流速球设备列表
export function ballList(data) {
  return request({
    url: "/v1/gnss/device/all",
    method: "post",
    data
  })
}
// 流速球历史轨迹
export function ballTrackList(data) {
  return request({
    url: "/v1/gnss/track/all",
    method: "post",
    data
  })
}
// 流速球设备列表sn
export function ballSnList(data) {
  return request({
    url: "/v1/gnss/device/sn",
    method: "post",
    data
  })
}
export function starsInfo(param) {
  return request({
    url:"/v1/gnss/device/satellite",
    method:"get",
    params:param,
  })
}
// 工作模式-保存配置
export function configWorkMode(param) {
  return request({
    url:"/v1/gnss/device/work_mode",
    method:"post",
    data:param,
  })
}
// 工作模式-原始数据查询
export function getWorkInfo(param) {
  return request({
    url:"/v1/gnss/device/work_mode",
    method:"get",
    params:param,
  })
}
// 工作模式-获取接入点
export function getAccessPoint(param) {
  return request({
    url:"/api/v1/receiver/access_point",
    method:"get",
    params:param,
  })
}
// 接收机管理-接收机信息 / 接收机状态-位置信息 / 接收机配置-天线高设置-获取天线高设置信息
export function receiverInfo(param) {
  return request({
    url:"/v1/gnss/device/detail",
    method:"get",
    params:param,
  })
}
// 设备管理-活动
export function getActivity(param) {
  return request({
    url:"/v1/gnss/device/activity",
    method:"get",
    params:param,
  })
}
// 接收机配置-NMEA消息信息集
export function getNMEA(param) {
  return request({
    url:"/v1/gnss/device/NMEA",
    method:"get",
    params:param,
  })
}
// 接收机配置-配置NMEA消息
export function setNMEA(param) {
  return request({
    url:"/v1/gnss/device/NMEA",
    method:"post",
    data:param,
  })
}
// 任务管理-删除任务
export function delTask(param) {
  return request({
    url:"/v1/gnss/task/delete",
    method:"post",
    data:param,
  })
}
// 任务管理-新建任务
export function addTask(param) {
  return request({
    url:"/v1/gnss/task/add",
    method:"post",
    data:param,
  })
}
// 任务管理-任务管理列表
export function taskList(param) {
  return request({
    url:"/v1/gnss/task/list",
    method:"post",
    data:param,
  })
}
// 任务管理-任务详情echarts
export function chartsList(param) {
  return request({
    url:"/v1/gnss/track/task/list",
    method:"post",
    data:param,
  })
}
// 任务管理-任务管理详情
export function getTaskInfo(param) {
  return request({
    url:"/v1/gnss/task/get",
    method:"get",
    params:param,
  })
}
// 任务管理-截面点对下拉
export function pointList(param) {
  return request({
    url:"/v1/gnss/line/list",
    method:"get",
    params:param,
  })
}
// 坐标系统-坐标系统列表 / 任务管理-坐标系统下拉
export function systemL(param) {
  return request({
    url:"/v1/gnss/coordinate/list",
    method:"get",
    params:param,
  })
}
// 坐标系统-获取坐标信息详情
export function systemInfo(param) {
  return request({
    url:"/v1/gnss/coordinate",
    method:"get",
    params:param,
  })
}
// 坐标系统-修改坐标信息
export function editSystem(param) {
  return request({
    url:"/v1/gnss/coordinate/update",
    method:"post",
    data:param,
  })
}
// 坐标系统-椭球信息
export function ballParam(param) {
  return request({
    url:"/v1/gnss/ellipsoid/list",
    method:"get",
    params:param,
  })
}
// 坐标系统-新建坐标系统
export function addSystem(param) {
  return request({
    url:"/v1/gnss/coordinate/add",
    method:"post",
    data:param,
  })
}
// 坐标系统-修改坐标系统
export function updSystem(param) {
  return request({
    url:"/v1/gnss/task/update",
    method:"post",
    data:param,
  })
}
// 坐标系统-批量删除坐标系统
export function delSystem(param) {
  return request({
    url:"/v1/gnss/coordinate/delete",
    method:"post",
    data:param,
  })
}
// 上传文件到oss
export function uploadFile(data) {
  return request({
    url:"/v1/gnss/line/upload",
    method:"post",
    data,
  })
}
// 获取oss文件
export function getFile(url) {
  return request({
    url,
    method:"get",
    responseType:"blob",
  })
}
// 获取截面点对关联的断面数据
export function getSectionOne(id) {
  return request({
    url:"/receiver/v1/gnss/line/getSection",
    method:"post",
    data:{
      id
    },
  })
}
// 更新截面点对关联的断面数据
export function updateSection(data) {
  return request({
    url:"/receiver/v1/gnss/line/updateSection",
    method:"post",
    data,
  })
}
// 获取指定时间段内轨迹日历
export function trackMark(sn) {
  let {yy,mm,dd} = tool.formartDate(new Date())
  return request({
    url:"/receiver/v1/gnss/track/mark",
    method:"post",
    data:{
      sn,
      model:1,
      beginTime:`${Number(yy)-1}-${mm}-${dd}`,
      endTime:`${Number(yy)}-${mm}-${dd}`
    },
  })
}
// // 升级文件上传
// export function receiverFileUploads(param) {
//   return request({
//     url:"http://47.104.131.194:23735/api/v2/farm/receiverFileUploads",
//     method:"post",
//     data:param,
//   })
// }
// // 流速球下发升级
// export function receiverUpdate(param) {
//   return request({
//     url:"/api/v1/receiver/firmware_upgrade",
//     method:"post",
//     data:param,
//   })
// }
