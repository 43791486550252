import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store/index.js'
import {
  getItem,
  setItem
} from "@/utils/localStorage"
import { setCookie } from "@/utils/cookie"
import {
  defaultSkin, skinName, tokenName
} from "@/config/project"

import { getRoutes } from "./baseRoutes"
import { generateRouter, handleRoutes } from "./tools/generateRouter"
Vue.use(VueRouter);
// 缓存原型上的push函数
const originPush = VueRouter.prototype.push
const originReplace = VueRouter.prototype.replace
// 给原型对象上的push指定新函数函数
VueRouter.prototype.push = function (location, onComplete, onAbort) {
  // 判断如果没有指定回调函数, 通过call调用源函数并使用catch来处理错误
  if (onComplete === undefined && onAbort === undefined) {
    return originPush.call(this, location, onComplete, onAbort).catch(() => { })
  } else { // 如果有指定任意回调函数, 通过call调用源push函数处理
    originPush.call(this, location, onComplete, onAbort)
  }
}
// replace同理处理
VueRouter.prototype.replace = function (location, onComplete, onAbort) {
  if (onComplete === undefined && onAbort === undefined) {
    return originReplace.call(this, location, onComplete, onAbort).catch(() => { })
  } else {
    originReplace.call(this, location, onComplete, onAbort)
  }
}
const router = new VueRouter({
  mode: "history",
  routes: getRoutes(),
});

const selfAddRoutes = (params) => {
  return new Promise(resolve => {
    const { routes, meunList, deep, orgRoutes } = generateRouter(handleRoutes(params));
    // console.log(routes, meunList, deep, orgRoutes);
    store.commit('permission/SET_ROUTES', orgRoutes)
    store.commit('permission/SET_MENU', meunList)
    router.matcher = new VueRouter({
      mode: 'history',
      routes: []
    }).matcher;
    getRoutes(routes).forEach(f => {
      router.addRoute(f)
    })
    resolve({
      name: `${deep[deep.length - 1]}`
    })
  })

}

router.selfAddRoutes = selfAddRoutes
router.getRoutes = getRoutes


router.beforeEach(async (to, from, next) => {
  let {
    token = '', id = "",type
  } = to.query;
  // 存本地皮肤缓存
  if (!getItem(skinName)) {
    setItem(skinName, JSON.stringify(defaultSkin))
    store.commit('skin/SET_SKIN_LIST', defaultSkin)
  } else {
    store.commit('skin/SET_SKIN_LIST', JSON.parse(getItem(skinName)))
  }
  if(type){
    store.commit('config/SET_MODEL', type)
  }
  if (to.path == '/login') return next();
  let isLogin = store.state.isLogin;
  const fullRoute = getItem('apiFullRoute') ? JSON.parse(getItem('apiFullRoute')) : '';
  if (isLogin && fullRoute) {
    if (to.path == '/login') {
      return next('/index');
    } else {
      next();
    }
  } else {
    if (to.name == 'login') {
      next()
    } else {
      next('/login');
    }

  }
  // next();
})

export default router;